<template>
    <label class="dropzone" :class="{dark:darkTheme, dragEnter: dragEnter}" :for="id" ref="dropzone" @drop.prevent="dropped" @dragenter="dragEntered" @dragleave="dragLeaved" @dragover="dragEntered" @dragover.prevent>
        <slot />
        <input type="file" @input="handleInput" ref="file" :accept="accept" :id="id" :name="id" multiple />
    </label>
</template>

<script>
export default {
    name: 'dropzone',
    props: {
        value: {
            type: Array,
            default: []
        },
        darkTheme: {
            type: Boolean,
            default: true
        },
        accept: {
            type: String,
            default: 'image/jpeg, image/gif, image/png'
        },
        id: {
            type: String,
            default: 'dzFileInput'
        }
    },
    data(){
        return {
            content: this.value,
            dragEnter: false,
        }
    },
    watch:{
        dragEnter(){
            this.$emit('dragging', this.dragEnter)
        }
    },
    methods:{
        handleInput (){
            if(this.$refs.file.files){
                let fileBuffer = []
                Array.prototype.push.apply(fileBuffer, this.$refs.file.files)
                this.handleFiles(fileBuffer)
            }
        },
        dragEntered(){
            this.dragEnter = true
        },
        dragLeaved(){
            this.dragEnter = false
        },
        dropped(event){
            this.dragEnter = false

            if(event.dataTransfer.files.length){
                let fileBuffer = []
                Array.prototype.push.apply(fileBuffer, event.dataTransfer.files)

                this.handleFiles(fileBuffer)
            }

        },

        handleFiles(files){
            const mimes = this.accept.split(', ')
            files = files.filter(f=>{return mimes.includes(f.type)})

            this.content = files
            this.$emit('input', this.content)

        }
    }
}
</script>

<style scoped lang="scss">
    .dropzone{
        display: block;
        width: 100%;
        border: solid 1px #9396a3;
        cursor: pointer;
        border-radius: 15px;
        &.dragEnter{
            border-color: #232425
        }
        input{
            position: fixed;
            top: -9999px;
            left: -9999px;
        }
        &.dark{
            border-color: #737892;
            &.dragEnter{
                border-color: #96a2b3;
            }
        }
    }
</style>
