<template>
    <div>
        <b-card>
            <b-card-header>
                <b-card-title>{{ $route.meta.pageTitle }}</b-card-title>
            </b-card-header>
            <b-card-body>
                <b-form @submit.prevent="save">
                    <b-row>
                        <b-col md="6">
                            <b-form-group label="Ürün kategorisi" label-for="category">
                                <v-select v-model="item.categoryId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="name" :options="productCategories" :clearable="false" inputId="category"
                                    :reduce="(option) => option.id" :loading="!productCategories.length">
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Ürün markası" label-for="brand">
                                <v-select v-model="item.brandId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="name" :options="productBrands" :clearable="false" inputId="brand"
                                    :reduce="(option) => option.id" :loading="!productBrands.length">
                                </v-select>
                            </b-form-group>
                        </b-col>


                        <b-col md="6">
                            <b-form-group label="Ürün adı" label-for="name">
                                <b-form-input id="name" v-model="item.name" />
                            </b-form-group>
                        </b-col>

                        <b-col md="3">
                            <b-form-group label="Barkod numarası" label-for="barcode">
                                <b-form-input id="barcode" v-model="item.barcode" />
                            </b-form-group>
                        </b-col>

                        <b-col md="3">
                            <b-form-group label="Kampanya" label-for="onCampaign">
                                <v-select v-model="item.onCampaign" dir="ltr" label="title"
                                    :options="[{ title: 'Kampanyalı ürün', id: '1' }, { title: 'Kampanya yok', id: '0' }]"
                                    :clearable="false" inputId="onCampaign" :reduce="(option) => option.id" />
                            </b-form-group>
                        </b-col>


                        <b-col md="6">
                            <b-form-group label="Birim Cinsi" label-for="unitTypeId">
                                <v-select v-model="item.unitTypeId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="title" :options="productUnitTypes" :clearable="false" inputId="unitTypeId"
                                    :reduce="(option) => option.id" :loading="!productUnitTypes.length">
                                </v-select>
                            </b-form-group>
                        </b-col>

                        <b-col md="6">
                            <b-form-group label="Birim" label-for="unit">
                                <b-form-input id="unit" v-model="item.unit" />
                            </b-form-group>
                        </b-col>

                        <b-col md="6">
                            <b-form-group label="Vergi oranı" label-for="tax">
                                <b-input-group>
                                    <b-form-input id="tax" v-model.number="item.tax" />
                                    <b-input-group-append is-text>%</b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col md="6">
                            <b-form-group label="Stok" label-for="stock">
                                <b-form-input id="stock" v-model.number="item.stock" />
                            </b-form-group>
                        </b-col>

                        <b-col md="6">
                            <b-form-group label="İndirim öncesi fiyat" label-for="oldPrice">
                                <b-input-group>
                                    <b-form-input id="oldPrice" v-model.number="item.oldPrice"
                                        :readonly="!item.onDiscount" />
                                    <b-input-group-append is-text>
                                        <b-form-checkbox v-model.number="item.onDiscount" value="1"
                                            class="custom-control-primary">
                                            <span class="pl-1"> İndirim</span>
                                        </b-form-checkbox>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group label="Güncel satış fiyatı" label-for="price">
                                <b-form-input id="price" v-model="item.newPrice" />

                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-alert variant="info" show class="p-2">
                                <small>
                                    <strong>Banka komisyonu:</strong> <span>{{ bankCommission }}
                                        ({{ $store.state.app.options.bank_commission }}%)</span><br>
                                    <strong>Petsomy komisyonu:</strong> <span>{{ petsomyCommission }}
                                        ({{ $store.state.app.options.petsomy_commission }}%)</span><br>
                                    <strong>Toplam komisyon:</strong> <span>{{ totalCommission }}
                                        ({{ totalCommissionPercent }})</span><br>
                                    <strong>Hakediş:</strong> <span>{{ progressPayment }}</span>

                                </small>
                            </b-alert>
                        </b-col>

                        <b-col md="6">
                            <b-form-group label="Ürün açıklaması" label-for="description">
                                <quill-editor v-model="item.description" :options="quillOptions" id="description"
                                    :class="{ dark: $store.state.appConfig.layout.skin === 'dark' }" />
                            </b-form-group>
                        </b-col>

                        <b-col md="6">
                            <b-form-group label="Özet bilgi" label-for="summary">
                                <quill-editor v-model="item.summary" :options="quillOptions" id="summary"
                                    :class="{ dark: $store.state.appConfig.layout.skin === 'dark' }" />
                            </b-form-group>
                        </b-col>

                        <b-col xs="12" v-if="item.id" class="d-flex justify-content-end">
                            <b-button @click="save" variant="outline-primary" :disable="saveOnProgress">
                                <feather-icon icon="SaveIcon" />
                                <span class="ml-1">Güncelle</span>
                            </b-button>
                        </b-col>

                    </b-row>
                </b-form>
            </b-card-body>
        </b-card>
        <b-card>
            <b-card-header>
                <b-card-title>Ürün varyasyonları</b-card-title>
                <b-button variant="flat-primary" class="btn-icon rounded-circle" @click="addVariant">
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </b-card-header>
            <b-card-body>
                <b-form v-for="(variant, key) in variants" :key="key">
                    <b-row class="position-relative pt-1">
                        <b-col md="2">
                            <b-form-group label="Birim" :label-for="'unit' + key">
                                <b-form-input :id="'unit' + key" v-model="variant.unit" />
                            </b-form-group>
                        </b-col>

                        <b-col md="2">
                            <b-form-group label="Stok" :label-for="'stock' + key">
                                <b-form-input :id="'stock' + key" v-model="variant.stock" />
                            </b-form-group>
                        </b-col>

                        <b-col md="2">
                            <b-form-group label="Fiyatı" :label-for="'price' + key">
                                <b-form-input :id="'price' + key" v-model="variant.price" />
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="Ürün görseli" :label-for="'fileName' + key">

                                <div class="d-flex justify-content-start align-items-start" style="padding-top: 3px;">
                                    <label :for="'fileName' + key" style="padding-right: 10px;">
                                        <img :src="$basePath + variant.fileName" v-if="variant.id && variant.fileName"
                                            class="variantThumb" />
                                        <img :src="variant.previewImage" v-else-if="variant.previewImage"
                                            :ref="'preview' + variant.key" class="variantThumb" />

                                        <span v-else class="btn btn-outline-primary btn-sm">Görsel seçin <feather-icon
                                                icon="ImageIcon"></feather-icon></span>
                                    </label>
                                    <b-button variant="flat-danger" class="btn-icon rounded-circle" v-if="variant.fileName"
                                        @click="removeVariantImage(variant)">
                                        <feather-icon icon="Trash2Icon" />
                                    </b-button>
                                </div>


                                <b-form-file class="visuallyHidden" :id="'fileName' + key" v-model="variant.newFileName"
                                    v-if="variant.id" :ref="'fileName' + variant.key"
                                    @input="(input) => updateVariantImage(variant, input)"
                                    accept="image/jpeg, image/png, image/gif" />

                                <b-form-file class="visuallyHidden" :id="'fileName' + key" v-model="variant.fileName" v-else
                                    :ref="'fileName' + variant.key" accept="image/jpeg, image/png, image/gif"
                                    @input="(input) => resolveImg(variant, input)" />

                            </b-form-group>
                        </b-col>
                        <b-col md="2" class="d-flex align-items-center">
                            <b-button-group>
                                <b-button variant="outline-primary" :disabled="!item.id" @click="saveVariant(variant)">
                                    <feather-icon icon="SaveIcon" />
                                </b-button>
                                <b-button variant="outline-primary" @click="deleteVariant(variant)">
                                    <feather-icon icon="Trash2Icon" />
                                </b-button>
                            </b-button-group>
                        </b-col>
                        <div class="uploadProgress" v-if="variant.uploadProgress">
                            <b-spinner :label="variant.uploadProgress" style="width: 4rem; height: 4rem;" />
                            <span class="percent">{{ variant.uploadProgress }}%</span>
                        </div>
                    </b-row>
                </b-form>
            </b-card-body>
        </b-card>
        <b-card title="Ürün Görselleri">
            <dropzone v-model="dzFiles" @dragging="f => dzDragging = f">
                <div class="filePickerMain" :class="{ dragging: dzDragging }">
                    <feather-icon icon="UploadIcon" size="45" />
                    <div class="title">Yüklemek istediğiniz dosyaları buraya sürükleyin veya dosya seçmek için tıklayın
                    </div>
                    <div class="subtitle">Yanlızca jpeg, gif ve png uzantılı, 4MB'a kadar olan dosyalar</div>
                </div>
            </dropzone>
            <b-row>
                <b-col md="6" lg="4" xl="3" v-for="(image, key) in images" :key="key" class="productPhotoItem" draggable>
                    <div>
                        <img :src="image.fileName ? $basePath + image.fileName : image.preview"
                            v-if="image.fileName || image.preview" class="productPhotoThumb" />
                        <b-button variant="gradient-danger" class="btn-icon rounded-circle remove" size="sm"
                            @click="removeImage(image)">
                            <feather-icon icon="Trash2Icon" />
                        </b-button>
                        <div class="progress" v-if="item.id && !image.id && !image.declined">
                            <b-spinner :label="image.progress" style="width: 4rem; height: 4rem;" />
                            <div class="percent">{{ image.progress }}</div>
                        </div>
                        <div class="declined" v-if="image.declined">
                            <feather-icon icon="AlertOctagonIcon" size="35" />
                            <div class="pt-2">Görsel yüklenemedi!</div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-card>

        <b-card v-if="!item.id">
            <b-row>
                <b-col xs="12" class="d-flex justify-content-end">
                    <b-button @click="save" variant="outline-primary" :disable="saveOnProgress">
                        <feather-icon icon="SaveIcon" />
                        <span class="ml-1">Kaydet</span>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import '@/assets/css/quill.snow.css'
import '@/assets/css/quill.snow.dark.css'

import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {
    BAlert,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BButtonGroup,
    BFormFile,
    BBadge,
    BSpinner,
    BListGroup,
    BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import store from "@/store"

import formData from 'form-data'
import fs from 'fs'

import dropzone from '@core/components/app-custom-components/dropzone'

export default {
    name: 'pForm',
    data() {
        return {
            productCategories: [],
            productBrands: [],
            productUnitTypes: [],
            variants: [],
            variantKey: 1,
            images: [],
            imageKey: 1,

            saveOnProgress: false,

            dzDragging: false,
            dzFiles: [],
            filesToUpload: [],

            quillOptions: {
                modules: {
                    toolbar: ['bold', 'italic', 'underline', 'strike', { 'list': 'ordered' }, { 'list': 'bullet' }, { 'header': [1, 2, 3, 4, 5, 6, false] }]
                },
                theme: 'snow'
            },

            dataKeys: ['categoryId', 'brandId', 'tax', 'onDiscount', 'onCampaign', 'stock', 'oldPrice', 'newPrice', 'name', 'barcode', 'summary', 'description', 'unit', 'unitTypeId',],
            dataTypes: {
                categoryId: 'int',
                brandId: 'int',
                tax: 'float',
                onDiscount: 'int',
                onCampaign: 'int',
                stock: 'int',
                oldPrice: 'float',
                newPrice: 'float',
                name: 'str',
                barcode: 'str',
                summary: 'str',
                description: 'str',
                unit: 'str',
                unitTypeId: 'int'
            },
        }
    },
    props: {
        item: Object
    },
    components: {
        BAlert,
        BCard,
        BCardBody,
        BCardHeader,
        BCardTitle,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BInputGroup,
        BInputGroupAppend,
        BButtonGroup,
        BFormFile,
        BBadge,
        BSpinner,
        BListGroup,
        BListGroupItem,

        vSelect,
        quillEditor,
        dropzone
    },
    created() {
        let t = this

        if (t.item.options) {
            t.variants = structuredClone(t.item.options)
            for (let i = 0; i < t.variants.length; i++) {
                t.variants[i].key = t.variantKey
                t.variantKey++
            }
        }

        if (t.item.images) {
            t.images = structuredClone(t.item.images)
            for (let i = 0; i < t.images.length; i++) {
                t.images[i].key = t.imageKey
                t.imageKey++
            }
        }
    },
    computed: {
        bankCommission() {
            return ((parseFloat(this.item.newPrice) / 100) * parseInt(this.$store.state.app.options.bank_commission)).toFixed(2)
        },
        petsomyCommission() {
            return ((parseFloat(this.item.newPrice) / 100) * parseInt(this.$store.state.app.options.petsomy_commission)).toFixed(2)
        },
        totalCommission() {
            let b = (parseFloat(this.item.newPrice) / 100) * parseInt(this.$store.state.app.options.bank_commission)
            let p = (parseFloat(this.item.newPrice) / 100) * parseInt(this.$store.state.app.options.petsomy_commission)
            return (b + p).toFixed(2)
        },
        totalCommissionPercent() {
            return (parseInt(this.$store.state.app.options.bank_commission) + parseInt(this.$store.state.app.options.petsomy_commission)) + '%'
        },
        progressPayment() {
            let b = (parseFloat(this.item.newPrice) / 100) * parseInt(this.$store.state.app.options.bank_commission)
            let p = (parseFloat(this.item.newPrice) / 100) * parseInt(this.$store.state.app.options.petsomy_commission)
            return (parseFloat(this.item.newPrice) - (b + p)).toFixed(2)
        },
    },
    watch: {
        dzFiles(e) {
            for (let i = 0; i < e.length; i++) {
                this.handleUnoploadedFile(e[i])
            }
            if (this.item.id) {
                this.handleUploadFile()
            }
        },
        'item.onDiscount'(e) {
            this.item.onDiscount = e ? e : 0
        }
    },
    beforeCreate() {
        let t = this

        /*Get Product Categories*/
        t.$http.get('misc/general/productCategories').then(response => {
            const { data } = response
            if (data.status) {
                t.productCategories = data.data
            } else if (data.message) {
                t.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Hata`,
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                        text: data.message,
                    },
                })
            }
        })

        /*Get Product Brands*/
        t.$http.get('misc/general/productBrands').then(response => {
            const { data } = response
            if (data.status) {
                t.productBrands = data.data
            } else if (data.message) {
                t.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Hata`,
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                        text: data.message,
                    },
                })
            }
        })

        /*Get Product Unit Types*/
        t.$http.get('misc/general/productUnitTypes').then(response => {
            const { data } = response
            if (data.status) {
                t.productUnitTypes = data.data
            } else if (data.message) {
                t.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Hata`,
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                        text: data.message,
                    },
                })
            }
        })

    },
    methods: {
        save() {
            if (this.item.id) {
                this.updateProduct()
            } else {
                this.addProduct()
            }
        },
        setFormData() {
            let data = new formData()

            for (let i = 0; i < this.dataKeys.length; i++) {
                let key = this.dataKeys[i]
                let val = this.item[key]

                switch (this.dataTypes[key]) {
                    case 'int':
                        val = parseInt(val)
                        if (!Number.isNaN(val) || this.item.id) {
                            val = Number.isNaN(val) ? '' : val
                            data.append(key, val)
                        }
                        break
                    case 'float':
                        val = parseFloat(val)
                        if (!Number.isNaN(val) || this.item.id) {
                            val = Number.isNaN(val) ? '' : val
                            data.append(key, val)
                        }
                        break
                    default:
                        data.append(key, val)
                        break
                }

            }

            return data
        },
        addProduct() {
            let t = this
            let data = t.setFormData()

            t.saveOnProgress = true
            store.commit('app/SET_LOADING', true)
            store.commit('app/SET_LOADINGSTR', 'ÜRÜN KAYDEDİLİYOR...')
            t.$http.post('product/addProduct', data).then(response => {
                let res = response.data
                let { data } = res
                if (res.status) {
                    if (res.message) {
                        t.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Başarılı!',
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: res.message
                            },
                        })
                    }

                    t.item.id = data.id.toString()
                    t.startInsertBatch()

                } else {
                    if (res.message) {
                        t.$bvToast.toast(res.error.description, {
                            title: 'Hata!',
                            variant: 'error',
                            solid: true
                        })
                    }
                    else if (res.error.description) {
                        t.$bvToast.toast(response.data.error.description, {
                            title: 'Hata!',
                            variant: 'error',
                            solid: true,
                        })
                    }
                    else if (res.error) {
                        t.$bvToast.toast(JSON.stringify(response.data.error).value, {
                            title: 'Hata!',
                            variant: 'error',
                            solid: true,
                        })
                    }
                    t.saveOnProgress = false
                    store.commit('app/SET_LOADING', false)
                }
            }).catch(error => {
                console.log(error)
                t.saveOnProgress = false
                store.commit('app/SET_LOADING', false)
            })
        },
        startInsertBatch(key = 0, file = false) {
            console.log('startInsertBatch key: ' + key)
            console.log('startInsertBatch file: ' + file)
            if (file) {
                let uIndex = this.filesToUpload.findIndex(f => { return f.key === key })
                if (!Number.isNaN(uIndex) && uIndex >= 0) {
                    store.commit('app/SET_LOADINGSTR', 'ÜRÜN GÖRSELİ KAYDEDİLİYOR (' + key + ' / ' + this.filesToUpload.length + ')')
                    this.handleUploadFile(key, false)
                } else {

                    this.saveOnProgress = false
                    store.commit('app/SET_LOADING', false)
                    this.$router.push({ name: 'edit-product', params: { product: this.item.id } })
                }
            } else {
                if (this.variants.length && this.variants[key]) {
                    store.commit('app/SET_LOADINGSTR', 'ÜRÜN VARYANTI KAYDEDİLİYOR (' + (key + 1) + ' / ' + this.variants.length + ')')
                    this.insertVariant(this.variants[key], key)
                } else {
                    this.startInsertBatch(1, true)
                }
            }

        },
        updateProduct() {
            let t = this
            let data = t.setFormData()

            t.saveOnProgress = true
            store.commit('app/SET_LOADING', true)
            t.$http.post('product/editProduct/' + t.item.id, data).then(response => {
                if (response.data.status) {
                    t.$bvToast.toast('Ürün başarıyla güncellendi.', {
                        title: 'Başarılı',
                        variant: 'success',
                        solid: true
                    })
                } else {
                    if (response.data.message) {
                        t.$bvToast.toast(response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1) + response.data.error.description ? ('\n' +
                            response.data.error.description.charAt(0).toUpperCase() + response.data.error.description.slice(1)) : '', {
                            title: 'Hata!',
                            variant: 'error',
                            solid: true
                        })
                    }
                    else if (response.data.erro.descriptionr) {
                        t.$bvToast.toast(response.data.error.description.charAt(0).toUpperCase() + response.data.error.description.slice(1), {
                            title: 'Hata!',
                            variant: 'error',
                            solid: true
                        })
                    }
                }
            }).catch(error => {
                console.log(error)
            }).then(() => {
                t.saveOnProgress = false
                store.commit('app/SET_LOADING', false)
            })
        },
        addVariant() {
            this.variants.push({
                id: null,
                unit: null,
                price: null,
                stock: null,
                fileName: null,
                productId: this.item.id ?? null,
                key: this.variantKey
            })
            this.variantKey++
        },
        saveVariant(variant) {
            if (variant.id) {
                this.updateVariant(variant)
            } else {
                this.insertVariant(variant)
            }
        },
        insertVariant(variant, key = false) {
            let t = this
            let data = new formData()

            data.append('unit', variant.unit)
            data.append('price', variant.price)
            data.append('stock', variant.stock)
            data.append('fileName', variant.fileName)

            t.$http.post('product/addVariant/' + t.item.id, data, {
                onUploadProgress: progress => t.variantUploadProgress(variant, progress)
            }).then(response => {
                variant.uploadProgress = false;
                t.$forceUpdate()
                if (response.data.status) {
                    t.$bvToast.toast('Ürün varyantı başarıyla eklendi.', {
                        title: 'Başarılı',
                        variant: 'success',
                        solid: true
                    })
                } else {
                    if (response.data.message) {
                        t.$bvToast.toast(response.data.message, {
                            title: 'Hata!',
                            variant: 'error',
                            solid: true
                        })
                    }
                    if (response.data.error) {
                        //TODO: field errorlar yerlerine yerleştirilecek
                    }
                }
            }).catch(err => { console.log(error) }).then(f => {
                console.log('insertVariant last then key:' + key)
                if (!Number.isNaN(key)) {
                    t.startInsertBatch(key + 1)
                }
            })

        },
        updateVariant(variant) {
            let t = this
            t.$http.post('product/editVariant/' + variant.id, variant).then(response => {
                if (response.data.status) {
                    t.$bvToast.toast('Ürün varyantı başarıyla güncellendi.', {
                        title: 'Başarılı',
                        variant: 'success',
                        solid: true
                    })
                } else {
                    if (response.data.message) {
                        t.$bvToast.toast(response.data.message, {
                            title: 'Hata!',
                            variant: 'error',
                            solid: true
                        })
                    }
                    if (response.data.error) {
                        //TODO: field errorlar yerlerine yerleştirilecek
                    }
                }
            });
        },
        removeVariantFromList(key) {
            let t = this
            t.variants = t.variants.filter(v => {
                return v.key !== key
            });
            return { status: true }
        },
        deleteVariant(variant) {
            let t = this
            this.$swal({
                title: 'Emin misiniz?',
                text: "Varyantı silmeniz durumunda bu işlemin geri dönüşü yoktur!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, Sil!',
                cancelButtonText: 'İptal',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return !variant.id ? t.removeVariantFromList(variant.key) :
                        t.$http.delete('product/deleteVariant/' + variant.id).then(result => {
                            if (result.data.status) {
                                t.removeVariantFromList(variant.key)
                            }
                            return result.data
                        })
                }
            }).then(result => {
                if (result.value) {
                    if (result.value.status) {
                        //Silindi mesajı
                        t.$swal({
                            title: 'Silindi',
                            text: 'Varyant başarıyla silindi.',
                            icon: 'success',
                            confirmButtonText: 'Peki.',
                            customClass: {
                                confirmButton: 'btn btn-primary'
                            }
                        })
                    } else {
                        //Hata mesajı w/result.value.message
                        t.$swal({
                            title: 'Hata',
                            text: result.value.message,
                            icon: 'error',
                            confirmButtonText: 'Tamam',
                            customClass: {
                                confirmButton: 'btn btn-warning'
                            }
                        })
                    }
                }
            })
        },
        updateVariantImage(variant, input) {
            if (!input) {
                return
            }
            let t = this
            let data = new formData()

            data.append('fileName', input)

            t.$http.post(
                'product/editVariantImage/' + variant.id,
                data,
                { onUploadProgress: progress => t.variantUploadProgress(variant, progress) }
            ).then((result) => {
                variant.uploadProgress = false
                if (result.data.status) {
                    variant.fileName = result.data.data.fileName
                } else {
                    t.$swal({
                        title: 'Hata!',
                        text: result.data.message ?? 'Dosya yüklenemedi.',
                        icon: 'error',
                        confirmButtonText: 'Tamam'
                    })
                }
                t.$forceUpdate()
            }).catch((error) => {
                variant.uploadProgress = false
                t.$forceUpdate()
            })
        },
        removeVariantImageFromList(variant) {
            variant.fileName = null
            variant.previewImage = false
            this.$forceUpdate()
            return { status: true }
        },
        removeVariantImage(variant) {
            let t = this
            this.$swal({
                title: 'Emin misiniz?',
                text: "Varyant görselini silmeniz durumunda bu işlemin geri alınamaz!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, Sil!',
                cancelButtonText: 'İptal',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: (a) => {
                    return !variant.id ? t.removeVariantImageFromList(variant) :
                        t.$http.post('product/editVariantImage/' + variant.id, { clear: 1 }).then(result => {
                            if (result.data.status) {
                                variant.fileName = null
                                t.$forceUpdate()
                            }
                            return result.data
                        })
                }
            }).then(result => {
                if (result.value) {
                    if (result.value.status) {
                        //Silindi mesajı
                        t.$swal({
                            title: 'Silindi',
                            text: 'Varyant görseli başarıyla Silindi.',
                            icon: 'success',
                            confirmButtonText: 'Tamam',
                            customClass: {
                                confirmButton: 'btn btn-primary'
                            }
                        })
                    } else {
                        //Hata mesajı w/result.value.message
                        t.$swal({
                            title: 'Hata',
                            text: result.value.message,
                            icon: 'error',
                            confirmButtonText: 'Tamam',
                            customClass: {
                                confirmButton: 'btn btn-warning'
                            }
                        })
                    }
                }
            })
        },
        variantUploadProgress(variant, progress) {
            let t = this
            let percent = Math.ceil((progress.loaded / progress.total) * 100)

            variant.uploadProgress = percent.toString();
            store.commit('app/SET_UPLOADPERCENT', percent)
            t.$forceUpdate()
        },
        resolveImg(variant, input) {
            if (!input) {
                return
            }

            const file = input
            const reader = new FileReader
            let t = this
            reader.onload = e => {
                let variantIndex = t.variants.findIndex(item => item.key === variant.key)
                t.variants[variantIndex].previewImage = e.target.result
                t.$forceUpdate()
            }
            reader.readAsDataURL(file)
        },
        resolvePhoto(file, key) {
            if (!file) {
                return
            }

            const reader = new FileReader
            reader.onload = e => {
                let imageIndex = this.images.findIndex((f) => f.key === key)
                this.images[imageIndex].preview = e.target.result
                this.$forceUpdate()
            }
            reader.readAsDataURL(file)
        },
        removeImageFromList(image) {
            this.images = this.images.filter(f => {
                return f.key !== image.key
            })
            this.filesToUpload = this.filesToUpload.filter(f => {
                return f.key !== image.key
            })
            this.$forceUpdate()
            return { status: true }
        },
        removeImage(image) {
            let t = this
            t.$swal({
                title: 'Emin misiniz?',
                text: 'Ürüm görselini silmek istediğinize emin misiniz? Bu işlem geri alınamaz!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, Sil!',
                cancelButtonText: 'İptal',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: (a) => {
                    return !image.id ? t.removeImageFromList(image) :
                        t.$http.delete('product/removeProductImage/' + image.id).then(result => {
                            if (result.data.status) {
                                t.images = t.images.filter(f => {
                                    return f.key !== image.key
                                })
                                t.$forceUpdate()
                            }
                            return result.data
                        })
                }
            }).then(result => {
                if (result.value) {
                    if (result.value.status) {
                        //Silindi mesajı
                        t.$swal({
                            title: 'Silindi',
                            text: 'Ürün görseli başarıyla silindi.',
                            icon: 'success',
                            confirmButtonText: 'Tamam',
                            customClass: {
                                confirmButton: 'btn btn-primary'
                            }
                        })
                    } else {
                        //Hata mesajı w/result.value.message
                        t.$swal({
                            title: 'Hata',
                            text: result.value.message,
                            icon: 'error',
                            confirmButtonText: 'Tamam',
                            customClass: {
                                confirmButton: 'btn btn-warning'
                            }
                        })
                    }
                }
            })
        },
        handleUnoploadedFile(file) {
            let t = this
            t.filesToUpload.push({
                key: t.imageKey,
                file: file
            })
            t.images.push({
                id: false,
                key: t.imageKey
            })
            t.resolvePhoto(file, t.imageKey)
            t.imageKey++
        },

        handleUploadFile(key = false, proceedNext = true) {
            let t = this
            let data = new formData()
            let imagesItem = false
            let uploadItem = false
            let iIndex = false
            let uIndex = false
            if (key) {
                iIndex = t.images.findIndex(f => { return f.key === key })
                uIndex = t.filesToUpload.findIndex(f => { return f.key === key })
                imagesItem = t.images[iIndex]
                uploadItem = t.filesToUpload[uIndex]
            } else {
                iIndex = t.images.findIndex(f => { return !f.id && !f.declined })
                imagesItem = t.images[iIndex]
                key = imagesItem.key
                let uIndex = t.filesToUpload.findIndex(f => { return f.key === key })
                uploadItem = t.filesToUpload[uIndex]
            }

            if (imagesItem && uploadItem) {

                data.append('fileName', uploadItem.file)
                data.append('default', 0)

                t.$http.post('product/addProductImage/' + t.item.id, data, {
                    onUploadProgress: progress => t.imageUploadProgress(progress, key)
                }).then(result => {
                    if (result.data.status) {
                        t.images[iIndex] = result.data.data
                    } else {
                        t.images[iIndex].declined = true
                    }
                }).catch(error => {
                    console.log(error)
                    t.images[iIndex].declined = true
                }).then(() => {
                    t.$forceUpdate()
                    if (proceedNext) {
                        t.handleUploadFile()
                    } else {
                        t.startInsertBatch((key + 1), true)
                    }
                })

            }
        },

        _handleUploadFile() {
            return
            let t = this
            let data = new formData()
            let findIndex = t.images.findIndex(f => {
                return !f.id && !f.declined
            })
            if (findIndex < 0) {
                return
            }

            let imageItem = t.images[findIndex]
            let uploadFileIndex = t.filesToUpload.findIndex(f => {
                return f.key === imageItem.key
            })
            if (uploadFileIndex < 0) {
                t.images[findIndex].declined = true
                t.handleUploadFile()
                return
            }

            let fileItem = t.filesToUpload[uploadFileIndex]

            data.append('fileName', fileItem.file)
            data.append('default', 0)

            t.$http.post('product/addProductImage/' + t.item.id, data, {
                onUploadProgress: progress => t.imageUploadProgress(progress, fileItem.key)
            }).then(result => {
                if (result.data.status) {
                    t.images[findIndex] = result.data.data
                } else {
                    console.log(result.data)
                    t.images[findIndex].declined = true
                }
            }).catch(error => {
                console.log(error)
                t.images[findIndex].declined = true
            }).then(() => {
                t.$forceUpdate()
                t.handleUploadFile()
            })
        },

        imageUploadProgress(progress, key) {
            let index = this.images.findIndex(f => f.key === key)
            this.images[index].progress = Math.floor((progress.loaded / progress.total) * 100).toString()
            store.commit('app/SET_UPLOADPERCENT', this.images[index].progress)
            this.$forceUpdate()
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.visuallyHidden {
    position: fixed;
    top: -9999px;
    left: -9999px;
}

.variantThumb {
    max-height: 150px;
    max-width: 100%;
    cursor: pointer;
}

.filePickerMain {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 120px;
    justify-content: center;
    align-items: center;
    padding: 30px 40px;
    flex-direction: column;
    color: #737892;

    .title {
        font-size: 15px;
        font-weight: bold;
        margin-top: 5px;
    }

    .subtitle {
        font-size: 13px;
    }

    &.dragging {
        color: #96a2b3
    }
}

.productPhotoItem {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;

    >div {
        border: solid 1px #525365;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 8px;
        position: relative;
        font-size: 16px;

        .productPhotoThumb {
            max-height: 200px;
            max-width: 100%;
            cursor: pointer;
        }

        .remove {
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 2
        }

        .progress {
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(42, 46, 63, .8);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 4;

            .percent {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                font-size: 15px;
                font-weight: bold;

                &:after {
                    content: '%';
                }
            }
        }

        .declined {
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(173, 42, 46, .6);
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: bold;
            color: #D8D9D5;
            z-index: 1;
            flex-direction: column;
        }
    }
}

.uploadProgress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(12, 12, 12, .4);
}

.percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 15px;
    color: #dedcd3;
    font-weight: bold;
    opacity: .6;
}
</style>
