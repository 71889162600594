<template>
    <p-form :item="defaults" />
</template>

<script>

import pForm from './pForm'

export default {
    name: 'add',
    components: {
        pForm
    },
    data(){
        return {
            defaults: {
                categoryId: '',
                brandId: '',
                tax: 18,
                stock: null,
                name: '',
                barcode: null,
                oldPrice: null,
                newPrice: 0,
                description: '',
                summary: '',
                unitTypeId: '',
                unit:'',
                onDiscount: 0,
                onCampaign: '0',
                id: null,
                options: [
                ]
            }
        }
    }
}
</script>

<style scoped lang="scss">

</style>
